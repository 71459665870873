<template>
  <PortraitOverlay v-if="portrait" />
  <MainMenu v-if="authStore.isAuthenticated" v-show="uxstore.mainMenu.show" />
  <div id="container">
    <Transition name="overlay" appear>
      <div v-if="uxstore.showOverlay" class="overlay"></div>
    </Transition>
<router-view v-slot="{ Component }">
      <keep-alive>
        <component
          v-if="router.currentRoute.value.meta.keepAlive"
          :is="Component"
          :key="router.currentRoute.value.fullPath"
        />
      </keep-alive>

      <component
        v-if="!router.currentRoute.value.meta.keepAlive"
        :is="Component"
        :key="router.currentRoute.value.fullPath"
      />
    </router-view>
  </div>
  <RightMenu v-if="uxstore.rightMenu.show">
    <component :is="uxstore.rightMenu.dynamicComponent" />
  </RightMenu>
</template>

<script setup>
import MainMenu from './components/MainMenu.vue';
import RightMenu from './components/RightMenu.vue';
import { useUXStore } from './stores/ux';
import { useAuthStore } from './stores/auth';
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';
import PortraitOverlay from '@/components/PortraitOverlay.vue'

import useConnectionManager from './services/composables/useConnectionManager';

useConnectionManager()
const authStore = useAuthStore()
const portrait = ref(false)
const uxstore = useUXStore()

const router = useRouter()


onMounted(() => {
  function isPortrait() {
    //portrait.value = window.innerWidth > window.innerHeight ? false : true;
    portrait.value = false
  }

  console.log(router.currentRoute.value.meta.keepAlive)

  window.addEventListener('resize', isPortrait)
  isPortrait()

  onBeforeUnmount(() => {
    window.removeEventListener('resize', isPortrait);
  })
})

</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100%;
  width: 100vw;
  overflow: hidden;
  position: relative;
}

#container {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: var(--primary-color);
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 5;
  background-color: #7373738f;
  transition-property: all;
  transition-delay: 2s;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
