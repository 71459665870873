import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import { VButton, VInput, VIcon, VLottie, VMap } from 'visivalab-components'
import router from './router'
import './registerServiceWorker'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import { useAuthStore } from './stores/auth'
import { axiosInstanceWithAuth } from '@/utils/utils'

// dynamic components
import RightMenuMap from '@/components/RightMenuMap.vue'
import MapFilter from '@/components/map/MapFilter.vue'

import PrimeVue from 'primevue/config'
import Aura from '@primeuix/themes/aura'

import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"

import 'visivalab-components/dist/visivalab-components.css'
import '@/assets/style.css'
import '@/assets/transitions.css'
import 'photoswipe/style.css';

// notification options
const toastOptions = {
    transition: "Vue-Toastification__fade",
    maxToasts: 20,
    newestOnTop: true
}

const pinia = createPinia()
const authStore = useAuthStore(pinia)

axiosInstanceWithAuth.get('/api/user').then((res) => {
    if (res.status === 200) {
        authStore.authenticated = true
        authStore.user = res.data
    }
}).catch(() => {
    authStore.authenticated = false
}).finally(() => {
    let app = createApp(App)

    app.use(router)
    app.use(pinia)
    app.use(VueViewer)
    app.use(Toast, toastOptions)
    app.use(PrimeVue, {
        theme: {
            preset: Aura
        }
    })

    // global components
    app.component('VMap', VMap)
    app.component('VButton', VButton)
    app.component('VInput', VInput)
    app.component('VIcon', VIcon)
    app.component('VLottie', VLottie)

    // dynamic components
    app.component('RightMenuMap', RightMenuMap)
    app.component('MapFilter', MapFilter)

    app.mount('#app')
})



