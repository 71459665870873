<template>
  <div class="modal-cont ai-center jc-center">
    <div class="modal flex-col gap-10">
      <VButton @click="cancel" class="close" :icon="require('@/assets/icons/cross.svg')" />
      <p>Stai validando definitivamente <br/>questa scheda. Vuoi continuare?</p>
      <p>NOTA: questa operazione <br/>non è reversibile</p>
      <div class="buttons-group gap-10 jc-center w-full">
        <VButton @click="cancel" class="button medium rounded gap-10 w-full"
          label="annulla" />
        <VButton @click="validate" class="button medium rounded gap-10 w-full"
          label="continua" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineEmits, onMounted } from 'vue'
import { useUXStore } from '@/stores/ux';
import { useRouter } from 'vue-router';

const emit = defineEmits(['cancel', 'validate'])
const uxstore = useUXStore()
const router = useRouter()

const cancel = () => {
  uxstore.showOverlay = false
  emit('cancel')
}

const validate = () => {
  emit('validate')
  uxstore.showOverlay = false
  router.push({ name: 'manutenzione' })
}

onMounted(() => {
  uxstore.showOverlay = true
})
</script>
<style>
.buttons-group{
  margin-top: 15px;
}

.buttons-group .button {
  text-align: center;
  justify-content: center;
}
.modal-icon-center {
    align-self: center;
}

.modal-cont {
    width: 100%;
    height: 100%;
    position: absolute;
}

.modal {
    width: 450px;
    padding: 40px;
    position: relative;
    background-color: white;
    z-index: 5;
}

.modal p {
    margin-top: 15px;
}

.modal .v-button:not(.close) {
    background-color: var(--base-dark-color);
    padding: 0 10px;
    --icon-color: var(--primary-color);
}

.modal .v-button .label {
    font-size: 0.75rem;
    letter-spacing: normal;
    text-align: left;
    color: var(--primary-color);
}

.modal .v-button.close {
    position: absolute;
    right: 15px;
    top: 15px;
}
</style>