<template>
  <section class="home">
    <div class="header gap-30 flex-col">
      <div class="row jc-between">
        <SectionTitle title="Manutenzione" :subtitle="monitorings.data.length + ' monitoraggi visibili'" icon="helmet"
          :border="true" />
        <div class="searchbox flex-col ai-end">
          <VInput type="text" :icon="require('@/assets/icons/search.svg')" :iconSize="15" v-model="searchQuery"
            @input="search" />
          <!-- <b>Ricerca Avanzata</b> -->
        </div>
      </div>

      <div class="row jc-between">
        <DropDown class="view" :options="monitoringTypes" :selected="selectedMonitoringType"
          @select="selectMonitoringType" label="Monitoraggio" />
        <VButton class="gap-10" @click="uiState.popup = true; uxStore.showOverlay = true" label="Aggiungi Nuovo"
          :icon="require('@/assets/icons/add.svg')" :reverse="true" />
      </div>

      <div class="row flex-col jc-between gap-10">
        <div class="row gap-10 inner-row">
          <DropDown :options="MONITORING_TIME_FILTERS" :selected="selectedTimeRange" @select="filterByCreationDate"
            :label="selectedTimeRange ? selectedTimeRange : 'Data'" />
          <DropDown :options="topographicElementsTypes" @select="filterByTe4Type"
            :selected="selectedTopographicElement4Type"
            :label="selectedTopographicElement4Type ? selectedTopographicElement4Type : 'Referente topografico'" />
          <DropDown :options="users" @select="filterByCompiler" :selected="selectedCompiler"
            :label="selectedCompiler ? selectedCompiler : 'Operatore'" />
          <DropDown :options="projects" @select="filterByProject" :selected="selectedProject"
            :label="selectedProject ? selectedProject : 'Progetto'" />
        </div>

        <div class="row filters jc-between ai-center gap-10 inner-row" v-show="selectedMonitoringType === 'punctual'">
          <div class="radio-buttons flex-row gap-10">
            <VIcon :src="require('@/assets/icons/filter.svg')" />
            <span>Filtra per: indici</span>
            <div class="gap-20">
              <RadioButton v-model="punctualMonitoringFilters.type" label="IA" value="ia" />
              <RadioButton v-model="punctualMonitoringFilters.type" label="IFR" value="ifr" />
            </div>
          </div>
          <div>
            <ColorCheckbox v-model="punctualMonitoringFilters.level[1]" boxColor="var(--bassa-color)" label="b" />
            <ColorCheckbox v-model="punctualMonitoringFilters.level[2]" boxColor="var(--media-bassa-color)"
              label="m-b" />
            <ColorCheckbox v-model="punctualMonitoringFilters.level[3]" boxColor="var(--media-color)" label="m" />
            <ColorCheckbox v-model="punctualMonitoringFilters.level[4]" boxColor="var(--media-alta-color)" label="m-a"
              :value="1" />
            <ColorCheckbox v-model="punctualMonitoringFilters.level[5]" boxColor="var(--alta-color)" label="a"
              :value="0" />
          </div>
        </div>
        <!-- <div class="row filters jc-start ai-center gap-10 inner-row" v-show="selectedMonitoringType === 'horizontal'">
          <ColorCheckbox boxColor="var(--alta-color)" label="alta" />
          <ColorCheckbox boxColor="var(--media-color)" label="medio" />
          <ColorCheckbox boxColor="var(--bassa-color)" label="bassa" />
        </div> -->
        <hr />
        <div class="grid sort-filters gap-20">
          <span></span>
          <ColumnSort label="Data" @sort="sort" name="date" ref="dateSort" @interface="getDateSortInterface" />
          <ColumnSort label="Progetto" @sort="sort" name="project" ref="projectSort"
            @interface="getProjectSortInterface" />
          <ColumnSort label="Operatore" @sort="sort" name="compiler" ref="compilerSort"
            @interface="getCompilerSortInterface" />
          <ColumnSort label="Ref. topografico" @sort="sort" name="topographicElement" ref="topographicElementSort"
            @interface="getTopographicReferenceSortInterface" />
          <ColumnSort label="Validazione" @sort="sort" name="validationElement" ref="validationElementSort"
            @interface="getValidationReferenceSortInterface" />
        </div>
      </div>
    </div>

    <div class="list flex-col gap-5" ref="itemList" id="itemList">
      <ItemList v-for="(item, index) in monitorings.data" :key="index" :item="item" @expand="expandItem(index, item)"
        @show="showElement" @edit="editElement" @link="linkElement" :open="activeItem == index" />
      <VLottie class="loading" v-if="loading" ref="animation" :src="require('@/assets/icons/loading.json')"
        :autoplay="true" :loop="true" />
      <div class="no-results" v-if="!loading && monitorings.data.length === 0">
        <span>Nessun risultato</span>
      </div>
    </div>

    <Transition name="aggiungi" appear>
      <AddNewPopUp v-if="uiState.popup" @select="openForm" v-on-click-outside="() => { uiState.popup = false }"
        @close="uiState.popup = false" />
    </Transition>
  </section>
</template>

<script>
import DropDown from '@/components/DropDown.vue';
import ItemList from '@/components/ItemList.vue';
import ColorCheckbox from '@/components/ColorCheckbox.vue';
import RadioButton from '@/components/RadioButton.vue';
import SectionTitle from '@/components/SectionTitle.vue';
import AddNewPopUp from '@/components/AddNewPopUp.vue';
import ColumnSort from '@/components/ColumnSort.vue';
import { ref, onMounted, reactive, onActivated, onBeforeUnmount } from 'vue'
import { axiosInstanceWithAuth } from '@/utils/utils'
import { useRouter } from 'vue-router';

import { vOnClickOutside } from '@vueuse/components'
import { useInfiniteScroll } from '@vueuse/core'
import { useUXStore } from '@/stores/ux'
import useMonitoring from '@/services/composables/useMonitoring'
import { MONITORING_TIME_FILTERS } from '@/utils/constants';

export default {
  name: 'HomeView',
  directives: {
    onClickOutside: vOnClickOutside
  },
  components: {
    DropDown,
    ItemList,
    ColorCheckbox,
    SectionTitle,
    AddNewPopUp,
    ColumnSort,
    RadioButton
  },
  watch: {
    punctualMonitoringFilters: {
      handler() {
        this.changedFilter = true
        this.search()
      },
      deep: true
    },
  },
  methods: {
    showElement(item) {
      this.router.push({
        name:
          'mostra-monitoraggio-' +
          (item.monitoring_type === 'quick'
            ? 'speditivo'
            : item.monitoring_type === 'punctual'
              ? 'puntuale'
              : 'orizzontale'),
        params: {
          id:
            item.monitoring_type === 'quick'
              ? item['quickMonitoringId']
              : item.monitoring_type === 'punctual'
                ? item['punctualMonitoringId']
                : item['horizontalMonitoringId']
        }
      })
    },
    openRoute(route) {
      this.closeForm()
      this.router.push(route)
    },
    getDateSortInterface(sortInterface) {
      this.$options.dateSortInterface = sortInterface
    },
    getProjectSortInterface(sortInterface) {
      this.$options.projectSortInterface = sortInterface
    },
    getCompilerSortInterface(sortInterface) {
      this.$options.compilerSortInterface = sortInterface
    },
    getTopographicReferenceSortInterface(sortInterface) {
      this.$options.topographicReferenceSortInterface = sortInterface
    },
    getValidationReferenceSortInterface(sortInterface) {
      this.$options.validationReferenceSortInterface = sortInterface
    },
    sort(field, direction) {
      this.changedFilter = true

      // Reset di tutti gli altri sorting
      switch (field) {
        case 'date':
          this.$options.projectSortInterface.reset()
          this.$options.topographicReferenceSortInterface.reset()
          this.$options.compilerSortInterface.reset()
          this.$options.validationReferenceSortInterface.reset()
          break
        case 'project':
          this.$options.dateSortInterface.reset()
          this.$options.topographicReferenceSortInterface.reset()
          this.$options.compilerSortInterface.reset()
          this.$options.validationReferenceSortInterface.reset()
          break
        case 'compiler':
          this.$options.dateSortInterface.reset()
          this.$options.projectSortInterface.reset()
          this.$options.topographicReferenceSortInterface.reset()
          this.$options.validationReferenceSortInterface.reset()
          break
        case 'topographicElement':
          this.$options.dateSortInterface.reset()
          this.$options.projectSortInterface.reset()
          this.$options.compilerSortInterface.reset()
          this.$options.validationReferenceSortInterface.reset()
          break
        case 'validationElement':
          this.$options.dateSortInterface.reset()
          this.$options.projectSortInterface.reset()
          this.$options.compilerSortInterface.reset()
          this.$options.topographicReferenceSortInterface.reset()
          break
        default:
          break
      }

      this.orderBy = { field, direction }

      if (this.orderBy.direction === 'none') {
        this.orderBy = null
      }
      this.updateMonitorings()
    },
    editElement(item) {
      // depending on the type of monitoring, open the right form
      switch (item.monitoring_type) {
        case 'quick':
          this.router.push({
            name: 'monitoraggio-speditivo',
            params: { id: item.quick_monitoring.id }
          })
          break
        case 'punctual':
          this.router.push({
            name: 'monitoraggio-puntuale',
            params: { id: item['punctualMonitoringId'] }
          })
          break
        case 'horizontal':
          this.router.push({
            name: 'monitoraggio-orizzontale',
            params: { id: item['horizontalMonitoringId'] }
          })
          break
        default:
          break
      }
    },
    linkElement(item) {
      // focus on the element on the map
      this.uxStore.rightMenu.data.polygons.forEach(polygon => {
        polygon.focus =
          item.topographic_element4 &&
          polygon.topographic_element_id === item.topographic_element_4_id
      })
    },
    selectMonitoringType(type) {
      this.changedFilter = true
      this.selectedMonitoringType = type.value
      this.updateMonitorings()
    },
    openForm(value) {
      this.uiState.popup = false
      this.uiState[value] = true
      this.uxStore.rightMenu.show = false

      switch (value) {
        case 'punctualMonitoringForm':
          this.router.push({ name: 'nuovo-monitoraggio-puntuale' })
          break
        case 'quickMonitoringForm':
          this.router.push({ name: 'nuovo-monitoraggio-speditivo' })
          break
        case 'horizontalMonitoringForm':
          this.router.push({ name: 'nuovo-monitoraggio-orizzontale' })
          break
      }
    },
    closeForm() {
      this.uxStore.rightMenu.show = true
    },
    search() {
      this.changedFilter = true
      // if user stays idle for 1 second, then search
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        this.updateMonitorings()
      }, 1000)
    }
  },
  setup() {
    const uxStore = useUXStore()
    const router = useRouter()

    // Stato per la lista e caricamento
    const loading = ref(true)
    const activeItem = ref(-1)
    const itemList = ref(null)
    const monitorings = reactive({ data: [] })

    const handleScroll = (evt) => {
      savedScrollPosition.value = evt.target.scrollTop
      console.log('scroll position saved', savedScrollPosition.value)
    }

    // Contatore pagine
    const pageCounter = ref(1)
    // Controllo se un filtro/sort ecc. è cambiato
    const changedFilter = ref(false)

    // Valori dropdown
    const topographicElementsTypes = ref([])
    const users = ref([])
    const projects = ref([])

    // Filtri
    const selectedTopographicElement4Type = ref(null)
    const selectedMonitoringType = ref(null)
    const selectedTimeRange = ref(null)
    const selectedCompiler = ref(null)
    const selectedProject = ref(null)
    const orderBy = ref(null)

    // Per la ricerca testuale
    const searchQuery = ref('')
    let searchTimeout = null

    // Filtri speciali per i “punctual”
    const punctualMonitoringFilters = reactive({
      type: '',
      level: { 1: false, 2: false, 3: false, 4: false, 5: false }
    })

    // Stato UI
    const uiState = reactive({
      popup: false
    })

    // Tipologie di monitoraggio
    const monitoringTypes = reactive([
      { label: 'Tipo di monitoraggio', icon: 'tutti.svg', value: null },
      { label: 'Orizzontale', icon: 'horizontal.svg', value: 'horizontal' },
      { label: 'Puntuale', icon: 'punctual.svg', value: 'punctual' }
    ])

    const savedScrollPosition = ref(0)

    // Per prevenire pull-to-refresh
    const preventPullToRefresh = () => {
      let lastTouchY = 0
      let maybePreventPullToRefresh = false

      const touchstartHandler = e => {
        if (e.touches.length !== 1) return
        lastTouchY = e.touches[0].clientY
        maybePreventPullToRefresh = window.pageYOffset === 0
      }

      const touchmoveHandler = async e => {
        const touchY = e.touches[0].clientY
        const touchYDelta = touchY - lastTouchY
        lastTouchY = touchY
        if (maybePreventPullToRefresh) {
          maybePreventPullToRefresh = false
          if (touchYDelta > 0) {
            e.preventDefault()
            // Clear the cache when pull-to-refresh happens
            await clearMonitorings()
            return
          }
        }
      }

      document.addEventListener('touchstart', touchstartHandler, false)
      document.addEventListener('touchmove', touchmoveHandler, false)
    }

    // Pulizia cache “offline” su un tipo di monitoraggio
    const clearMonitorings = async () => {
      const useMonitorings = useMonitoring('punctual-monitoring')
      await useMonitorings.clearDataToSync('punctual-monitoring')
    }

    // Caricamento “base” (singola pagina) con eventuale callback
    const updateMonitorings = (customListener = null) => {
      loading.value = true

      // Se i filtri (o sort) sono cambiati, resettiamo la paginazione
      if (changedFilter.value) {
        pageCounter.value = 1
        changedFilter.value = false
        // Torniamo in cima
        if (itemList.value) itemList.value.scrollTop = 0
        // Svuotiamo la lista
        monitorings.data = []
      }

      axiosInstanceWithAuth
        .get('/api/bff/monitoring/search', {
          params: {
            query: searchQuery.value,
            page: pageCounter.value,
            topographicElement4TypeId: selectedTopographicElement4Type.value,
            compilerId: selectedCompiler.value,
            projectId: selectedProject.value,
            creationDate: selectedTimeRange?.value,
            monitoringType: selectedMonitoringType?.value,
            orderBy: orderBy.value,
            punctualMonitoringFilters: punctualMonitoringFilters
          }
        })
        .then(res => {
          if (customListener) {
            customListener(res.data)
          } else {
            monitorings.data = res.data.data
          }

          // Aggiorna i poligoni nel rightMenu
          uxStore.rightMenu.data.polygons = monitorings.data
            .map(item => {
              if (!item.topographic_element4) return null
              return {
                style: { color: '#' + item.color },
                tooltip:
                  item.topographic_element4?.name +
                  '<br>Regione: ' +
                  item.topographic_element4?.regionName +
                  '<br>Insula: ' +
                  item.topographic_element4?.insulaName +
                  '<br>Unità: ' +
                  item.topographic_element4?.unityName +
                  '<br>Ambiente: ' +
                  item.topographic_element4?.environmentName,
                ...item.topographic_element4
              }
            })
            .filter(i => i && i.polygon)

          loading.value = false
        })
    }

    // Funzione che ricarica “tutte” le pagine già caricate
    // (da 1 a pageCounter.value), per avere dati freschi.
    const refreshAllPages = async () => {
      loading.value = true
      const aggregatedData = []

      // Ricarico ogni pagina fin qui caricata
      for (let p = 1; p <= pageCounter.value; p++) {
        const res = await axiosInstanceWithAuth.get('/api/bff/monitoring/search', {
          params: {
            query: searchQuery.value,
            page: p,
            topographicElement4TypeId: selectedTopographicElement4Type.value,
            compilerId: selectedCompiler.value,
            projectId: selectedProject.value,
            creationDate: selectedTimeRange?.value,
            monitoringType: selectedMonitoringType?.value,
            orderBy: orderBy.value,
            punctualMonitoringFilters: punctualMonitoringFilters
          }
        })
        aggregatedData.push(...res.data.data)
      }

      // Sostituisco i dati con la nuova versione aggiornata
      monitorings.data = aggregatedData

      // Aggiorno i poligoni con i nuovi dati
      uxStore.rightMenu.data.polygons = monitorings.data
        .map(item => {
          if (!item.topographic_element4) return null
          return {
            style: { color: '#' + item.color },
            tooltip:
              item.topographic_element4?.name +
              '<br>Regione: ' +
              item.topographic_element4?.regionName +
              '<br>Insula: ' +
              item.topographic_element4?.insulaName +
              '<br>Unità: ' +
              item.topographic_element4?.unityName +
              '<br>Ambiente: ' +
              item.topographic_element4?.environmentName,
            ...item.topographic_element4
          }
        })
        .filter(i => i && i.polygon)

      loading.value = false
    }

    // Filtri rapidi
    const filterByTe4Type = te4Type => {
      changedFilter.value = true
      selectedTopographicElement4Type.value = te4Type.value
      updateMonitorings()
    }

    const filterByCompiler = compiler => {
      changedFilter.value = true
      selectedCompiler.value = compiler.value
      updateMonitorings()
    }

    const filterByProject = project => {
      changedFilter.value = true
      selectedProject.value = project.value
      updateMonitorings()
    }

    const filterByCreationDate = date => {
      changedFilter.value = true
      selectedTimeRange.value = date.value
      updateMonitorings()
    }

    // Espansione item (accordion)
    const expandItem = (index, item) => {
      if (item.specificElements) {
        activeItem.value = activeItem.value === index ? -1 : index
      } else {
        activeItem.value = -1
      }
    }

    // Infinite scroll
    useInfiniteScroll(
      itemList,
      () => {
        pageCounter.value++
        updateMonitorings(data => {
          // Se l’ultima pagina è vuota, torniamo indietro
          if (data.length === 0) {
            pageCounter.value--
          } else {
            monitorings.data = [...monitorings.data, ...data.data]
          }
        })
      },
      { distance: 200 }
    )

    // onMounted: inizia le configurazioni una sola volta
    onMounted(async () => {
      preventPullToRefresh()

      // Caricamento iniziale di dropdown & simili
      axiosInstanceWithAuth.get('/api/topographic-element-4-type').then(res => {
        topographicElementsTypes.value = res.data.data.map(type => ({ label: type.name, value: type.id }))
        topographicElementsTypes.value.unshift({ label: 'Referente topografico', value: null })
        // Seleziono subito "tutti" i TE4
        filterByTe4Type(topographicElementsTypes.value[0])
      })

      axiosInstanceWithAuth.get('/api/users').then(res => {
        users.value = res.data
          .map(user => ({ label: user.name + ' ' + user.surname, value: user.id }))
          .sort((a, b) => (a.label < b.label ? -1 : 1))
      })

      axiosInstanceWithAuth.get('/api/project').then(res => {
        projects.value = res.data.data.map(project => ({ label: project.name, value: project.id }))
      })

      setTimeout(() => {
        if(itemList.value) {
          itemList.value.addEventListener('scroll', handleScroll)
          console.log('scroll listener added')
        }
        
      }, 1000)
    })

    onBeforeUnmount(() => {
      if(itemList.value) {
        itemList.value.removeEventListener('scroll', handleScroll)
      }
    })

    // onActivated: ogni volta che si ritorna qui “sotto keep-alive”,
    // ricarichiamo tutte le pagine già viste per aggiornarle.
    onActivated(async () => {
      refreshAllPages()


      if (itemList.value) {
        itemList.value.scrollTop = savedScrollPosition.value
      }
    })

    return {
      // reactive refs
      monitorings,
      topographicElementsTypes,
      selectedTopographicElement4Type,
      pageCounter,
      MONITORING_TIME_FILTERS,
      users,
      projects,
      selectedProject,
      selectedCompiler,
      selectedMonitoringType,
      monitoringTypes,
      changedFilter,
      savedScrollPosition,
      loading,
      orderBy,
      searchQuery,
      searchTimeout,
      uiState,
      itemList,
      selectedTimeRange,
      activeItem,
      punctualMonitoringFilters,

      // store
      uxStore,
      router,

      // methods
      updateMonitorings,
      refreshAllPages,
      filterByTe4Type,
      filterByCompiler,
      filterByProject,
      filterByCreationDate,
      expandItem,
      preventPullToRefresh,

      // watchers
      // ...
    }
  }
}
</script>

<style>
.home {
  flex-direction: column;
  width: 100%;
  margin: 30px;
  font-size: 13px;
  color: var(--secondary-color);
  --icon-color: var(--secondary-color);
}

.home * {
  color: var(--secondary-color);
}

.row span {
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.inner-row:last-child {
  margin: 10px 0 20px 0;
}

.home .color-checkbox {
  margin: 0 10px;
}

.searchbox {
  width: 50%;
  min-width: 200px;
  max-width: 400px;
}

.searchbox .v-input {
  border: 1px solid black;
  border-radius: 40px;
  height: 30px;
  padding: 1px 10px;
  width: 100%;
  font-size: 0.8125rem;
}

.searchbox b {
  text-decoration: underline;
  padding-top: 5px;
}

.sort-filters {
  grid-template-columns: 30px 90px 1fr 0.5fr 0.6fr 110px 1fr;
  text-align: left;
  padding: 0 20px;
}

.header .color-checkbox {
  height: 15px;
}

.list {
  height: 100%;
  overflow-y: auto;
  flex: 1;
  width: calc(100% + 15px);
  padding-right: 10px;
}

.dropdown.view .selected,
.dropdown.view .category .box {
  background-color: var(--base-light-color);
  --icon-color: var(--secondary-color);
}

.dropdown.view .category .box:hover {
  background-color: #dedede;
}

.dropdown:not(.view) p {
  color: var(--primary-color);
}

@media only screen and (max-height: 672px) {
  .home {
    margin: 20px 25px 0px 25px;
  }

  .home .header {
    gap: 10px;
  }

  .inner-row:last-child {
    margin: 5px 0 10px 0;
  }
}

.no-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-size: 1.5rem;
  color: var(--secondary-color);
}

.home .filters .radiobutton {
  height: 15px;
  font-weight: 500;
}

.home .filters .radiobutton .v-lottie {
  border: 1px solid black;
  border-radius: 50%;
}
</style>
